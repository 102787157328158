body {
  overflow-x: hidden;
}

/* Animation */

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 2.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.home-component {
  margin-top: 80px;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .img-fluid {
    width: 100px;
    height: 100px;
  }
  .helpline {
    top: 0;
  }
  .help-links {
    columns: 1;
    margin-left: 0;
  }

  nav {
    height: 50px;
  }

  .navbar-brand {
    margin-top: 12px;
    width: 200px;
    font-weight: bold;
  }
  .fa-shield-virus {
    margin-top: 0px;
    font-size: 15px;
  }
  .navbar-brand:hover {
    font-size: 20px;
    transition: font-size 1.5s;
  }
  .navbar-nav {
    margin-left: 60%;
  }
  .nav-link {
    color: #108885;
    height: 50px;
  }

  .nav-item {
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
    margin-right: 10px;
  }
  .nav-item:last-child {
    margin-right: 0;
  }

  .nav-item:after {
    content: "";
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width 0.2s ease, background-color 0.2s ease;
  }
  .nav-item:hover:after {
    width: 100%;
    background: #0e6665;
  }
  .nav-item:hover {
    height: 50px;
  }
  .navlink:hover {
    color: #0e6665;
  }
  .modal-link {
    margin-top: 20px;
    font-weight: bold;
  }
  .ind-pnl-1 {
    height: 35px;
  }

  .global-data {
    height: 155px;
  }
  .g-pnl {
    margin-top: 10px;
    height: 125px;
    padding-top: 30px;
  }
  .g-rec {
    height: 135px;
  }
  .g-pn {
    margin-top: 10px;
  }
  .social-btns {
    margin-left: 30%;
  }

  .ind-helpline {
    width: 97%;
  }
  .ind-helpline {
    width: 97%;
  }
  #country-table thead th {
    width: 24.3%;
  }
  #country-table td {
    width: 25%;
  }
  .st-tab {
    width: 95%;
  }
  #state-table thead th:nth-of-type(1) {
    width: 24%;
    font-size: 11px;
  }

  #state-table tbody {
    width: 103%;
  }
  #state-table thead th {
    width: 17%;
    font-size: 11px;
  }
  #state-table td {
    width: 20%;
  }
  .dist-data {
    width: 20%;
    height: 35px;
    font-size: 12px;
  }
  #districtTable thead tr {
    width: 85%;
  }
  #districtTable td {
    width: 20%;
  }
  .dist-thead th {
    width: 20%;
    border: 1px solid rgb(255, 255, 255);
  }
  .flag {
    height: 15px;
    width: 25px;
    margin-right: 10px;
  }
  .update-lbl-ind-1 {
    display: none;
  }
  .update-lbl-ind * {
    display: inline;
  }
  .source-para {
    margin-left: 1%;
    text-align: justify;
    padding: 10px;
    width: 91.5%;
  }

  #state-row {
    height: 35px;
  }
  #state-row td {
    width: 25%;
  }
  .st-name {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-y: auto;
    overflow-x: auto;
  }
  .n-lbl {
    margin-top: 15px;
    margin-bottom: 45px;
  }

  .news-item {
    height: 170px;
    padding: 5px;
    margin-top: 10px;
  }
  .ind-news-item {
    width: 100%;
    margin-left: -7%;
  }
  .news-title {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .news-container {
    margin-top: 2%;
  }
  .news-time {
    color: #133e4a;
    float: right;
  }

  .table-responsive {
    overflow-x: hidden;
  }
  .state-table-container {
    width: 95%;
    margin-left: 4%;
  }

  .state-district-table {
    margin-left: 0%;
    width: 100%;
  }

  .map {
    width: 450px;
    margin: 10% 10% 10% 16%;
  }
  .switch-chart-type {
    margin-top: 10px;
    margin-left: -35%;
    margin-bottom: -30px;
  }
  .alert-danger {
    margin-right: 7%;
  }
  /*COvid Page*/
  .covid-page {
    width: 60%;
  }

  .footer-row {
    height: 200px;
    padding: 30px;
  }
}

@media screen and (max-width: 700px) {
  .home-component {
    margin-top: 30px;
  }
  .main {
    margin-top: 15%;
  }
  .progress {
    margin-top: 15%;
  }
  .navbar-brand {
    width: 150px;
    height: 30px;
    margin-top: -10px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .fa-shield-virus {
    margin-top: 10px;
    font-size: 15px;
  }
  .nav-link {
    color: #108885;
  }

  .nav-link:hover {
    height: 35px;
    background-color: #000;
    color: #fff;
  }
  .navbar-toggler {
    margin-left: 10%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .hamburger {
    margin-top: 0px;
    font-size: 20px;
    color: rgb(241, 9, 9);
  }
  .flag {
    height: 70%;
    width: 20%;
    margin-right: 5px;
  }
  .update-lbl-ind {
    display: none;
  }
  .update-lbl-ind-1 {
    margin-top: -20px;
    color: #6c757d;
    font-weight: bold;
  }

  .update-lbl-ind-1 * {
    display: inline;
  }
  .i-data {
    font-size: 150%;
  }

  .i-data-ind {
    font-size: 90%;
  }

  .india {
    margin-top: 20%;
  }
  .ind-pnl-head {
    height: 40px;
  }

  .ind-up-lbl {
    text-align: left;
  }

  .ind-spr {
    display: none;
  }
  .ind-stats li {
    font-size: 12px;
  }

  .ind-stats li i {
    font-size: 10px;
  }
  .ind-stats li p {
    font-size: 13px;
  }
  .social-btns {
    margin-left: 13%;
  }
  .st-tab {
    width: 95%;
  }
  .updateLabel {
    float: right;
  }
  .g-pnl {
    margin-top: -2px;
    margin-left: -6%;
    width: 112%;
  }
  .g-pn {
    margin-top: -10px;
  }
  #country-table th {
    width: 25%;
  }
  #state-table {
    margin-top: 0px;
  }
  #state-row td {
    height: 30px;
  }
  #state-table thead th {
    width: 20%;
    font-size: 11px;
  }
  #state-table td {
    width: 20%;
  }
  .state-head th {
    font-size: 60%;
  }
  .dist-data {
    font-size: 9px;
    height: 35px;
    display: flex;
    flex-direction: row;
  }
  .st-dist-span {
    font-weight: bold;
    font-size: 11px;
  }
  .st-dist-data {
    font-weight: bold;
    font-size: 14px;
  }
  .ind-news-item {
    width: 108%;
  }
  .news-title {
    font-size: 14px;
    margin-bottom: 10%;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .news-item {
    height: 122px;
    margin-top: 8px;
    margin-left: -12%;
    padding: 3px;
  }
  .news-desc {
    display: none;
  }

  .news-title a {
    font-size: 12px;
    word-spacing: 1px;
  }
  .news-img {
    width: 80px;
    height: 80px;
  }

  th,
  td {
    font-size: 70%;
  }

  .dist-name {
    font-size: 10px;
  }
  .country-name {
    font-size: 80%;
  }
  .map {
    margin: 5%;
  }
  .st-top-distt {
    margin-left: 4%;
    width: 92%;
  }
  .state-district-table {
    margin-left: 1%;
    width: 70%;
  }
}
/*
@media screen and (max-width: 450px) {
  .progressbar li {   
    display: flex;
    flex-direction: column;
  }
}
*/
@media screen and (max-width: 360px) {
  .ind-pnl-1 {
    height: 90px;
  }
}
/*a:visited {
  color: rgb(63, 61, 61);
}*/
.App {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
}
.world {
  font-size: 10px;
}

nav {
  background-color: rgb(255, 255, 255);
}
.nav-item {
  text-align: center;
}
.navbar {
  border-bottom: 1px solid #ccc;
}
.navbar-brand {
  padding: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.footer-row {
  background-color: #43464a;
}

.nav-link {
  color: #108885;
  text-align: center;
  font-weight: bold;
}

.modal-link {
  color: #fff;
  height: 50%;
  display: none;
}
.nav-item .active {
  text-decoration: underline;
}
.loading-label .country-name {
  text-align: left;
}
.state-table {
  margin: 5%;
}

.progress-bar {
  -webkit-animation: reverse progress-bar-stripes 0.5s linear infinite;
  -moz-animation: reverse progress-bar-stripes 0.5s linear infinite;
  -ms-animation: reverse progress-bar-stripes 0.5s linear infinite;
  -o-animation: reverse progress-bar-stripes 0.5s linear infinite;
  animation: reverse progress-bar-stripes 0.5s linear infinite;
}
.source-para {
  color: #67857e;
  font-weight: bold;
  border: 1px solid #67857e;
  border-radius: 5px;
  margin-left: 6.5%;
  width: 91.5%;
}

.fa-globe {
  font-size: 40px;
  color: green;
}
.panel-heading {
  font-weight: bold;
}

.panel-body .data {
  font-weight: bold;
  font-size: 150%;
}

.ind-pnl {
  float: left;
}
.ind-panel {
  margin-top: 1%;
}

.icon {
  font-size: 20px;
}
.fa-redo {
  color: green;
}
.fa-ambulance {
  color: red;
}
.fa-bell {
  font-size: 12px;
  margin-left: -5%;
}
.ind-panel {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.ind-panel-1 {
  border: 1px solid #000;
  border-radius: 5px;
}
.progressbar {
  margin-bottom: 30px;
  overflow: hidden;
}

.progressbar-li {
  list-style-type: none;
  /*width: 33.33%;
  float: left;*/
  position: relative;
  font: 500 13px/1;
  font-weight: bold;
  text-align: center;
}

.progressbar-li .icon::after {
  content: "";
  height: 5px;
  width: 100%;
  display: block;
  position: absolute;
  top: 12px;
  z-index: -1;
}

.progressbar-li:last-child .icon::after {
  content: none;
}

.progressbar-li p {
  font-weight: bold;
  font-size: 20px;
}

.ind-stats {
  margin-bottom: 30px;
  overflow: hidden;
  margin-left: -5%;
}

.ind-stats li {
  list-style-type: none;
  width: 33.3%;
  height: 95%;
  float: left;
  position: relative;
  font-weight: bold;
  text-align: center;
  border-right: 4px solid #fff;
}

.ind-stats li .icon::after {
  content: "";
  background: #133e4a;
  height: 5px;
  width: 100%;
  display: block;
  position: absolute;
  top: 12px;
  z-index: -1;
}

.ind-stats,
li p {
  font-weight: bold;
  font-size: 15px;
}
.state-stats {
  margin-bottom: 30px;
  overflow: hidden;
  margin-left: -5%;
}

.state-stats li {
  list-style-type: none;
  width: 33.3%;
  height: 95%;
  float: left;
  position: relative;
  font-weight: bold;
  text-align: center;
  border-right: 4px solid #fff;
}

.state-stats li .icon::after {
  content: "";
  background: #133e4a;
  height: 5px;
  width: 100%;
  display: block;
  position: absolute;
  top: 12px;
  z-index: -1;
}

.state-stats,
li p {
  font-weight: bold;
  font-size: 12px;
}
.ind-stats-1 {
  margin-bottom: 30px;
  overflow: hidden;
  margin-left: -5%;
}

.ind-stats-1 li {
  list-style-type: none;
  width: 25%;
  height: 95%;
  float: left;
  position: relative;
  font-weight: bold;
  text-align: center;
  border-right: 4px solid #fff;
}

.ind-stats-1 li .icon::after {
  content: "";
  background: #133e4a;
  height: 5px;
  width: 100%;
  display: block;
  position: absolute;
  top: 12px;
  z-index: -1;
}

.ind-stats-1,
li p {
  font-weight: bold;
  font-size: 15px;
}
.state-stats-1 {
  margin-bottom: 30px;
  overflow: hidden;
  margin-left: -5%;
}

.state-stats-1 li {
  list-style-type: none;
  width: 25%;
  height: 95%;
  float: left;
  position: relative;
  font-weight: bold;
  text-align: center;
  border-right: 4px solid #fff;
}

.state-stats-1 li .icon::after {
  content: "";
  background: #133e4a;
  height: 5px;
  width: 100%;
  display: block;
  position: absolute;
  top: 12px;
  z-index: -1;
}

.state-stats-1,
li p {
  font-weight: bold;
  font-size: 12px;
}
ul {
  width: 100%;
}
ul li {
  list-style-type: none;
  margin-left: 0;
  text-align: justify;
}

.stats {
  font-weight: bold;
}

.news-section {
  width: 100%;
}
.news-desc {
  text-align: justify;
  color: rgb(102, 98, 98);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.trending-lbl {
  font-weight: bold;
  color: rgb(247, 10, 2);
  text-transform: uppercase;
}

.news-container {
  margin-left: -5%;
}

.news-title {
  font-weight: bold;
  text-align: justify;
  font-family: Helvetica, Arial, sans-serif;
}
.news-title a {
  color: #000;
  text-decoration: none;
}

.news-title a:hover {
  text-decoration: underline;
}

.news-time {
  float: right;
  color: #0f2a31;
  font-weight: 500;
}

.helpline-data {
  margin-top: 8%;
}
.ind-helpline {
  height: 90%;
}
.help-links {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-all;
}
.help-link {
  text-decoration: none;
  color: wheat;
  font-weight: bold;
  background-color: #133e4a;
}
.help-link-item {
  text-align: left;
}
.help-link:hover {
  color: #fff;
  text-decoration: none;
}
.helpline-data {
  display: block;
  margin-top: 0px;
}
.pat-head {
  margin-top: 100px;
}

.pat-data {
  background-color: #133e4a;
}

.pat-data-holder {
  border: 0;
  text-align: left;
}

.hr {
  height: 1px;
}
.btn-search {
  border: 0;
}

.ind-spr {
  border: 2px solid #ccc;
  width: 104.1%;
  margin-left: -2.5%;
  margin-top: -4%;
}

.ind-link {
  color: #fff;
  text-decoration: none;
  height: 100%;
}
.ind-link:hover {
  color: #000;
  text-decoration: none;
  border-radius: 10%;
}

.footer-row {
  border: 1px solid #000;
  text-align: center;
  color: #fff;
}
.f-link {
  text-transform: uppercase;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.ind-pnl-body {
  padding: 0;
}

.help-link-item {
  margin-top: 2px;
  border-radius: 2%;
  text-align: justify;
  list-style-type: disc;
}

.help-link-item a {
  color: #133e4a;
  font-weight: bold;
  text-transform: capitalize;
}
.news-img {
  margin-top: 15%;
  margin-left: 5%;

  border: 1px solid rgb(122, 118, 118);
}

.state-link {
  color: #000;
  text-decoration: underline;
}
.state-link:visited {
  color: #000;
  text-decoration: underline;
}
.state-link:hover {
  color: #fff;
  font-weight: bold;
  font-size: 120%;
}
.state-page {
  margin-top: 80px;
  margin-bottom: 20%;
}
.st-top-distt {
  border: 1px solid #ccc;
  border-radius: 6px;
  height: 85%;
  margin-top: 10px;
}
.top-dist * {
  display: inline;
}
/* For District Table*/

.dist-tab {
  margin-left: 10%;
}
.st-dist-name {
  text-align: left;
  align-items: center;
  color: #616a72;
  font-weight: bold;
  height: 52px;
  overflow: hidden;
}

.st-dist-data {
  color: #616a72;
  font-weight: bold;
  font-size: 12px;
}
.btn-outline-dark {
  border: 1px solid #000;
  float: left;
  margin-right: 5%;
}
.btn-outline-dark:hover {
  color: #fff;
}
/*Tool Tip*/
.t-tip:hover {
  border: 0px solid #fff;
}
.t-tip:hover:after {
  content: "Awating details";
  font-family: Arial;
  position: absolute;
  height: 1.25rem;
  background-color: #000;
  color: #fff;
}
.t-tip-state:hover {
  border: none;
}
.t-tip-state:hover:after {
  content: "Based on 2019 population projection by NCP";
  font-family: Arial;
  position: absolute;
  font-size: 12px;
  background-color: #000;
  color: #fff;
}
.st-name {
  color: #282c30;
}
.st-name:hover {
  color: rgb(68, 65, 65);
  text-decoration: underline;
  cursor: pointer;
}

.st-name * {
  display: inline;
}
/*
iframe {
  display: none;
}*/
.adv {
  margin-left: 10%;
}
.link-src {
  height: 255px;
  width: 130px;
  border: none;
  margin-top: 8px;
}
.confirmed-map {
  background-color: #e6f3fe;
  border: 3px solid #0275d8;
  border-radius: 5%;
}
.active-map {
  background-color: #fff0b3;
  border: 3px solid #e6b800;
  border-radius: 5%;
}

.recovered-map {
  background-color: #cae8ca;
  border: 3px solid #008000;
  border-radius: 5%;
}
.death-map {
  background-color: rgba(255, 7, 58, 0.12549);
  border: 3px solid #d9534f;
  border-radius: 5%;
}

/*Sources Page*/

.jumbotron {
  background-color: rgb(250, 244, 244);
  box-shadow: 0 0 6px rgba(138, 135, 135, 0.6);
  -moz-box-shadow: 0 0 6px rgba(112, 107, 107, 0.6);
  -webkit-box-shadow: 0 0 6px rgba(109, 102, 102, 0.6);
  -o-box-shadow: 0 0 6px rgba(75, 70, 70, 0.6);
}

.sources-container {
  margin-top: 80px;
  color: #606c71;
}

.sources-container h4 {
  color: #159957;
  text-align: left;
  margin-left: 5%;
}

.objective-list {
  margin-left: 5%;
  margin-bottom: 30px;
}

.objective-list li {
  color: #338491;
  font-size: 16px;
  font: "Open Sans";
  list-style-type: square;
}
.objective-list li a {
  font-size: 14px;
  color: #0275d8;
  text-decoration: underline;
}
.dt-head {
  border-bottom: 1px solid rgb(175, 171, 171);
  padding: 0px;
  font-weight: 500;
  margin-bottom: 20px;
}

.ind-src {
  background-color: #e1e1e6;
  text-align: left;
  margin-left: 5%;
}
.w-src {
  background-color: rgb(216, 212, 212);
  text-align: left;
  margin-left: 5%;
}
.src-tab {
  margin-left: 5%;
}

.link-thead tr th {
  text-align: center;
  font-size: 18px;
}

.src-title {
  text-align: left;
  font-size: 14px;
  color: #6f7070;
  font-weight: 400;
}
.src-link {
  color: #1e6bb8;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.src-link:hover {
  text-decoration: underline;
}

.last-col::after {
  content: "";
  display: none;
}

.dist-thead {
  color: #fff;
  font-size: 20%;
  text-align: center;
}

.switch-chart-type * {
  display: inline-block;
}
.alert-danger {
  margin-left: 8%;
  padding: 5px;
  height: 37px;
  width: 120px;

  border-radius: 5px;
}
.alert-danger:hover {
  font-weight: bold;
  cursor: pointer;
  border: 2px solid rgb(218, 49, 49);
}

.global-data {
  border: 1px solid #ccc;
  margin-left: 1%;
  margin-right: 1%;
}

.btn-facebook {
  background: #3b5998;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 10px;
  font-weight: bold;
}
.btn-facebook:link,
.btn-facebook:visited {
  color: #fff;
}
.btn-facebook:active,
.btn-facebook:hover {
  background: #263961;
  color: #fff;
}
.social-btn i {
  width: 22px;
}
.social-btn {
  padding: 5px;
}
.social-btn .btn {
  height: 30px;
}

.twt-btn {
  background-color: #00acee;
  color: #fff;
  font-weight: bold;
}
.twt-btn:active,
.twt-btn:hover {
  background-color: #0c7daa;
  color: #fff;
}
.fa-twitter-square {
  font-size: 20px;
  color: #eeeeee;
}
.twt-btn i {
  vertical-align: middle;
}
.whtsp-btn {
  background-color: rgb(44, 183, 66);
  color: #fff;
  font-weight: bold;
}
.whtsp-btn i {
  font-size: 20px;
  vertical-align: middle;
}

.whtsp-btn:active,
.whtsp-btn:hover {
  background-color: rgb(37, 160, 56);
  color: #fff;
}
/*Covid Page*/
.covid-info {
  background-color: #f4f4f8;
}
.covid-page .card {
  margin-top: 80px;
  padding: 20px;
  text-align: justify;
  color: rgb(112, 112, 112);
  font-size: 13px;
  letter-spacing: 1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px;
  border-radius: 6px;
}
.symp-title {
  color: grey;
  font-size: 16px;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 20px;
}
.symptoms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  padding: 7px 0;
}
.o-symptoms-container,
.symptoms-container {
  text-align: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  font-weight: 700;
  width: 100%;
  background: #fff;
  margin: 4px;
  color: #000;
}
.symp-img {
  margin-top: 20px;
}
.symp-name {
  margin-top: 3px;
  font-weight: 500;
  letter-spacing: 0.13px;
  color: #000;
  padding: 5px 18px 0;
}
.symp-desc {
  color: #707070;
  font-size: 12px;
  padding: 10px;
  justify-content: center;
  text-align: center;
  display: flex;
  letter-spacing: 0.04px;
}
.myth-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}
.myth-text {
  padding: 16px;
}
.myth-title {
  letter-spacing: 0.07px;
  text-align: left;
  font-size: 14px;
}
.myth-desc {
  text-align: left;
  margin-top: 4px;
  letter-spacing: 1.06px;
  color: #707070;
  font-size: 12px;
}
.myth-red {
  color: #f47272;
  font-weight: 700;
  font-size: 14px;
}

.stats-conf-ind {
  background-color: rgba(77, 149, 231, 0.125);
}
.stats-active-ind {
  background-color: rgba(192, 192, 25, 0.747);
}
.stats-rec-ind {
  background-color: rgba(40, 167, 69, 0.12549019607843137);
  color: #115521;
}

.stats-dec-ind {
  background-color: rgba(255, 7, 58, 0.12549);
}

.ind-permillion {
  background-color: rgba(150, 109, 63, 0.063);
  color: rgba(122, 88, 50, 0.6);
}
