td {
  font-size: 11px;
  overflow: hidden;
  align-items: center;
}

.table-fixed tbody {
  height: 700px;
  overflow-y: auto;
  overflow-x: auto;
  right: -20px;
  width: 100%;
}

.table-fixed tbody,
.table-fixed tr {
  display: block;
}

.table-fixed tbody td,
.table-fixed thead > tr > th {
  float: left;
}

.table-fixed tbody th::after {
  content: "";
  clear: both;
  display: none;
}

.table-fixed tbody tr::after {
  content: "";
  clear: both;
  display: block;
}
.country-table {
  margin-top: 5px;
}
#country-table thead th {
  text-align: center;
  color: aliceblue;
  border: 1px solid rgb(185, 183, 183);
  background-color: #8d8d8d;
}
#country-table td {
  width: 25%;
}

#state-table th {
  border: none;
  text-align: left;
}

.country-name {
  text-align: left;
  color: #000;
  background-color: #b1bbc5;
  border: 1px solid rgba(16, 136, 133, 0.8);
}
.country-name:hover {
  background-color: rgba(16, 136, 133, 0.8);
  color: #fff;
}

.state-table {
  margin-left: 5%;
  margin-right: 0%;
}
.state-head {
  width: 90%;
}
